import baseAxios from '@/api/base';

baseAxios.defaults.baseURL = `${process.env.VUE_APP_APIPATH}`;

/** Orders api */
/**
 * 全部訂單
 * @param {object} data {
  "pageIndex": 0,
  "pageSize": 0,
  "data": {}
  }
 */
export const GetOrders = (data) => baseAxios.post('/FrontEnd/Orders/OrdersByMid', data);

/**
 * 待付款訂單
 * @param {object} data {
  "pageIndex": 0,
  "pageSize": 0,
  "data": {}
  }
 */
export const GetOrdersByMidState = (data) => baseAxios.post('/FrontEnd/Orders/OrdersByMidState', data);

/**
 * 待付款訂單
 * @param {object} data {
  "Id": 0
  }
 */
export const GetOrderById = (data) => baseAxios.post('/FrontEnd/Orders/FrontEndOrderById', data);

/**
 * 取消訂單
 * @param {object} data {
  "Id": 0
  }
 */
export const CancelOrderById = (id) => baseAxios.post('/FrontEnd/Orders/FrontEndCancelOrder', { id });

/**
 * 重新訂單
 * @param {object} data {
  "Id": 0
  }
 */
export const CopyOrderById = (id) => baseAxios.post('/FrontEnd/Orders/FrontEndCopyOrder', { id });

/**
 * 開始上課-課程列表
 * @param {object} data {
  "pageIndex": 0,
  "pageSize": 0,
  "data": {}
  }
 */
export const GetGotoClass = (data) => baseAxios.post('/FrontEnd/Orders/GotoClassByMid', data);

/**
 * 訂單列表-取得自選老師
 */
export const GetOrderCourseOptional = (data) => baseAxios.post('/FrontEnd/Orders/OrderCourseOptional', data);
