<template>
  <!-- 主要內容 -->
  <section class="container cs-table pb-8" data-style="1">
    <!-- 訂單資訊 -->
    <div class="border-shadow mb-6">
      <h2 class="table__title">訂單資訊</h2>
      <div class="table__body">
        <div class="row border-bottom mb-3">
          <div class="dl-horizontal col-12 col-md-6">
            <dl>
              <dt>訂單編號</dt>
              <dd>
                <p>{{ OrderData.orderCode }}</p>
              </dd>
            </dl>
            <dl>
              <dt>銷售金額</dt>
              <dd>
                <p>{{ OrderData.orderAmountPrice | toCurrency }}</p>
              </dd>
            </dl>
            <dl>
              <dt>付款金額</dt>
              <dd>
                <p class="text-red">{{ OrderData.orderActualPrice | toCurrency }}</p>
              </dd>
            </dl>
          </div>
          <div class="dl-horizontal col-12 col-md-6">
            <dl>
              <dt>訂單狀態</dt>
              <dd>
                <p>{{ OrderData.statusName }}</p>
              </dd>
            </dl>
            <dl>
              <dt>訂單成立時間</dt>
              <dd>
                <p>{{ OrderData.orderDate | toTaipei | moment('YYYY-MM-DD HH:mm:ss') }}</p>
              </dd>
            </dl>
            <dl>
              <dt>活動折扣</dt>
              <dd>
                <p>{{ (OrderData.orderAmountPrice - OrderData.orderActualPrice) | toCurrency }}</p>
              </dd>
            </dl>
          </div>
        </div>
        <div class="row border-bottom mb-3">
          <div class="col-12 col-md-6 dl-horizontal">
            <dl>
              <dt>付款方式</dt>
              <dd>
                <p>{{ OrderData.orderPayModeName }}</p>
              </dd>
            </dl>
          </div>
          <div class="col-12 col-md-6 dl-horizontal">
            <dl>
              <dt>付款時間</dt>
              <dd>
                <p>{{ OrderData.orderPayDate | toTaipei | moment('YYYY-MM-DD HH:mm:ss') }}</p>
              </dd>
            </dl>
          </div>
          <div class="col-12 col-md-6 dl-horizontal" v-if="OrderData.orderPayMode == 2">
            <dl>
              <dt>繳費代碼</dt>
              <dd>
                <p>{{ OrderData.ecPayPaymentNo }}</p>
              </dd>
            </dl>
          </div>
          <div class="col-12 col-md-6 dl-horizontal" v-if="OrderData.orderPayMode == 6">
            <dl>
              <dt>繳費銀行代碼</dt>
              <dd>
                <p>{{ OrderData.ecPayBankCode }}</p>
              </dd>
            </dl>
          </div>
          <div class="col-12 col-md-6 dl-horizontal" v-if="OrderData.orderPayMode == 6">
            <dl>
              <dt>繳費虛擬帳號</dt>
              <dd>
                <p>{{ OrderData.ecPayvAccount }}</p>
              </dd>
            </dl>
          </div>
          <div
            class="col-12 col-md-6 dl-horizontal"
            v-if="OrderData.orderPayMode == 2 || OrderData.orderPayMode == 6"
          >
            <dl>
              <dt>繳費期限</dt>
              <dd>
                <p>{{ OrderData.ecPayExpireDate | toTaipei | moment('YYYY-MM-DD HH:mm:ss') }}</p>
              </dd>
            </dl>
          </div>
          <div class="col-12 col-md-6 dl-horizontal" v-if="OrderData.orderPayMode == 3">
            <dl>
              <dt>付款帳號後五碼</dt>
              <dd>
                <p>{{ OrderData.ecPayWebAtmaccNo }}</p>
              </dd>
            </dl>
          </div>
          <div class="col-12 col-md-6 dl-horizontal" v-if="OrderData.orderPayMode == 5">
            <dl>
              <dt>分期期數</dt>
              <dd>
                <p>{{ OrderData.ecPayStage }}</p>
              </dd>
            </dl>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6 dl-horizontal">
            <dl>
              <dt>發票</dt>
              <dd>
                <p>{{ OrderData.orderInvoiceTypeName }}</p>
              </dd>
            </dl>
          </div>
          <!-- 手機條碼 -->
          <template v-if="OrderData.orderInvoiceType === 2">
            <div class="col-12 col-md-6 dl-horizontal">
              <dl>
                <dt>手機條碼</dt>
                <dd>
                  <p>{{ OrderData.memberInvoiceCode }}</p>
                </dd>
              </dl>
            </div>
          </template>
          <!-- 三聯式 -->
          <template v-if="OrderData.orderInvoiceType === 3">
            <div class="col-12 col-md-6 dl-horizontal">
              <dl>
                <dt>抬頭</dt>
                <dd>
                  <p>{{ OrderData.memberInvoiceTitle }}</p>
                </dd>
              </dl>
            </div>
            <div class="col-12 col-md-6 dl-horizontal">
              <dl>
                <dt>統一編號</dt>
                <dd>
                  <p>{{ OrderData.memberInvoiceTaxId }}</p>
                </dd>
              </dl>
            </div>
            <div class="col-12 col-md-6 dl-horizontal">
              <dl>
                <dt>發票聯絡地址</dt>
                <dd>
                  <p>
                      {{ OrderData.memberInvoiceZipCode }}
                      {{ OrderData.memberInvoiceCounty }}
                      {{ OrderData.memberInvoiceCity }}
                      {{ OrderData.memberInvoiceAddress }}
                  </p>
                </dd>
              </dl>
            </div>
            <div class="col-12 col-md-6 dl-horizontal">
              <dl>
                <dt>發票聯絡電話</dt>
                <dd>
                  <p>{{ OrderData.memberInvoicePhone }}</p>
                </dd>
              </dl>
            </div>
            <div class="col-12 col-md-6 dl-horizontal">
              <dl>
                <dt>發票聯絡信箱</dt>
                <dd>
                  <p>{{ OrderData.memberInvoiceEmail }}</p>
                </dd>
              </dl>
            </div>
          </template>
        </div>
      </div>
    </div>
    <!-- 收件人資訊 -->
    <div class="border-shadow mb-6">
      <h2 class="table__title">訂單資訊</h2>
      <div class="table__body">
        <div class="row">
          <div class="col-12 col-md-6 dl-horizontal">
            <dl>
              <dt>收件人姓名</dt>
              <dd>
                <p>{{ OrderData.orderName }}</p>
              </dd>
            </dl>
            <dl>
              <dt>收件人電話</dt>
              <dd>
                <p>{{ OrderData.orderPhone }}</p>
              </dd>
            </dl>
            <dl>
              <dt>收件人地址</dt>
              <dd>
                <p>
                  {{ OrderData.orderZipCode }}&nbsp;{{ OrderData.orderCounty }}&nbsp;
                  {{ OrderData.orderCity }}&nbsp; {{ OrderData.orderAddress }}
                </p>
              </dd>
            </dl>
            <dl>
              <dt>寄送注意事項</dt>
              <dd>
                <p>{{ OrderData.orderNote }}</p>
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </div>
    <!-- 訂單課程資訊 -->
    <div class="border-shadow mb-6">
      <h2 class="table__title">訂單資訊</h2>
      <div class="table__body">
        <div class="row">
          <div class="col-12">
            <ul class="cs-table mb-6" data-style="4">
              <li class="table__head d-none d-md-flex">
                <div class="table__head-td">課程套裝名稱</div>
                <div class="table__head-td">套裝類型</div>
                <div class="table__head-td">商品價格</div>
                <div class="table__head-td">服務啟用起始日期</div>
                <div class="table__head-td">服務啟用結束日期</div>
              </li>
              <li class="table__item" v-for="(item, index) in OrderData.ordersDetails" :key="index">
                <div class="table__item-td table__item--course">
                  {{ item.courseName }}
                  <button
                    v-if="item.courseType === 2"
                    data-toggle="modal"
                    data-target="#teacherMsg"
                    class="btn btn-info btn-sm"
                    @click="GetOrderCourseOptional(item)"
                  >
                    <i class="fa fa-file-text-o"></i>
                  </button>
                </div>
                <div class="table__item-td table__item--courseType">{{ item.courseTypeName }}</div>
                <div class="table__item-td table__item--sum">
                  {{ item.courseActualPrice | toCurrency }}
                </div>
                <div class="table__item-td table__item--start">
                  {{ item.courseViewStartDate | toTaipei | moment('YYYY-MM-DD') }}
                </div>
                <div class="table__item-td table__item--end">
                  <span class="text-danger">
                    {{ item.courseViewEndDate | toTaipei | moment('YYYY-MM-DD') }}</span
                  >
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <!-- 返回 -->
    <div class="d-flex justify-content-end">
      <router-link class="btn btn-primary btn-hover-shadow px-6 py-2 py-md-3" to="/member/orders">
        返回列表
      </router-link>
    </div>

    <!-- Modal -->
    <div
      class="modal fade bd-example-modal-lg"
      id="teacherMsg"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title mb-3" id="exampleModalCenterTitle">
              {{ optionalChoose.title }}
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body p-3 p-md-6">
              <div class="row">
                <div class="col-12">
                  <ul class="cs-table mb-6" data-style="4">
                    <li class="table__head d-none d-md-flex">
                      <div class="table__head-td">科目</div>
                      <div class="table__head-td">自選講師</div>
                    </li>
                    <li
                      class="table__item"
                      v-for="(item, index) in optionalChoose.list"
                      :key="index">
                      <div class="table__item-td table__item--subjectName">
                        {{ item.subjectName }}
                      </div>
                      <div class="table__item-td table__item--teachers">
                        {{ item.teachers }}
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { GetOrderById, GetOrderCourseOptional } from '@/api/OrderApi';
import { GetCityByZipCode } from '@/lib/zipcode';

export default {
  data() {
    // 建立你的初始化 model data
    return {
      tempOptionalChoose: {},
      optionalChoose: {
        title: '',
        list: [],
      },
      OrderData: {
        orderId: 0,
        orderCode: '',
        statusName: '',
        orderPayModeName: '',
        orderAmountPrice: 0,
        orderActualPrice: 0,
        orderDate: '',
        payStatusName: '',
        discountPrice: null,
        memberId: 0,
        orderAddress: '',
        orderName: '',
        orderNote: '',
        orderPayDate: '',
        orderPayMode: 0,
        orderPhone: '',
        ordersDetails: [],
        status: 1,
      },
    };
  },
  created() {
    this.GetData();
  },
  methods: {
    GetData() {
      // 取得 url id 的方法
      const { id } = this.$route.params;
      GetOrderById({ Id: id }).then((response) => {
        if (response.data.statusCode === 0) {
          this.OrderData = response.data.data;
          if (this.OrderData.orderCity === null || this.OrderData.orderCity === '') {
            this.OrderData.orderCity = GetCityByZipCode(this.OrderData.orderZipCode);
          }
          this.$emit('update:currentPage', {
            name: '',
            title: this.OrderData.orderCode,
            path: '',
          });
        }
      });
    },
    GetOrderCourseOptional(date) {
      this.optionalChoose.title = '';
      this.optionalChoose.list = [];
      const { id } = this.$route.params;
      const postData = {
        OrderId: id,
        CourseId: date.courseId,
      };
      if (this.tempOptionalChoose[date.courseId]) {
        this.optionalChoose.title = date.courseName;
        this.optionalChoose.list = [...this.tempOptionalChoose[date.courseId]];
        this.DialogTableVisible = true;
        return '';
      }
      return GetOrderCourseOptional(postData).then((res) => {
        if (res.data.statusCode === 0) {
          const newData = this._.chain(res.data.data)
            .groupBy('subjectId')
            .map((value) => (
              {
                subjectId: value[0].subjectId,
                subjectName: value[0].subjectName,
                teachers: this._.join(value.map((item) => item.teacherName), '，'),
              })).value();
          this.$set(this.tempOptionalChoose, date.courseId, newData);
          this.optionalChoose.title = date.courseName;
          this.optionalChoose.list = [...newData];
          this.DialogTableVisible = true;
        }
      });
    },
  },
};
</script>

<style></style>
